<template>
  <div class="flex flex-col h-full">

    <template v-if="isCurrentUser">

      <layout-page-head-section>
        <div class="flex flex-col sm:flex-row gap-2 sm:gap-4 sm:items-start">

          <div class="flex flex-1 gap-4 order-2 sm:order-1">
            <client-only>
              <div @click="editThumbnail({user: currentUser})" role="button" title="Edit Profile Photo">
                <user-avatar :user="currentUser" :size="avatarSize" />
              </div>
            </client-only>

            <div class="flex flex-col">
              <layout-page-heading class="mb-1">{{ currentUser.name }}</layout-page-heading>

              <div class="2xl:w-4/5" v-if="currentUser.bio">
                <span class="text-base text-gray-500 whitespace-pre-wrap leading-none" v-html="currentUser.bio.substring(0, bioPreviewLength).concat(currentUser.bio.length > bioPreviewLength ? '...' : '')" />
                <u-button class="ml-3" variant="link" :padded="false" @click="openDetailsModal({user: currentUser})" v-if="currentUser.bio.length > bioPreviewLength">View More</u-button>
              </div>
            </div>
          </div>

          <div class="flex gap-2 order-1 sm:order-2 justify-end">
            <u-button-group>
              <u-button :icon="COMMON_ICONS.information" variant="outline" color="charcoal" @click="openDetailsModal({user: currentUser})" />
              <u-button :icon="COMMON_ICONS.share" variant="outline" color="charcoal" @click="shareProfile({user: currentUser})" />
              <user-current-user-settings-dropdown :user="currentUser" v-model:privacy="privacy"/>
            </u-button-group>
            <core-responsive-button :icon="COMMON_ICONS.edit" @click="editProfile({user: currentUser})">Edit Profile</core-responsive-button>
          </div>
        </div>
      </layout-page-head-section>

    </template>

    <template v-else>
      <layout-page-head-section>
        <div class="flex flex-col sm:flex-row gap-2 sm:gap-4 sm:items-start">

          <div class="flex flex-1 gap-4 order-2 sm:order-1">
            <!--todo-click to preview-->
            <client-only>
              <div class="relative">
                <user-avatar :user="currentProfileUser" :size="avatarSize" />
                <user-friend-badge class="absolute z-10 right-0 top-0" v-if="isFriend" />
              </div>
            </client-only>

            <div class="flex flex-col">
              <layout-page-heading class="mb-1">{{currentProfileUser.name}}</layout-page-heading>

              <div v-if="isPending" class="mb-3" key="1">
                <p class="text-sm text-gray-500">would like to connect</p>
                <user-pending-actions :user="currentProfileUser" />
              </div>

              <div class="2xl:w-4/5" v-else-if="currentProfileUser.bio">
                <span class="text-base text-gray-500 whitespace-pre-wrap leading-none" v-html="currentProfileUser.bio.substring(0, bioPreviewLength).concat(currentProfileUser.bio.length > bioPreviewLength ? '...' : '')" />
                <u-button class="ml-3" variant="link" :padded="false" @click="openDetailsModal({user: currentProfileUser})" v-if="currentProfileUser.bio.length > bioPreviewLength">View More</u-button>
              </div>

            </div>
          </div>

          <div class="flex gap-2 order-1 sm:order-2 justify-end">
            <u-button-group>
              <u-tooltip text="View Details">
                <u-button :icon="COMMON_ICONS.information" variant="outline" color="charcoal" @click="openDetailsModal({user: currentProfileUser})" />
              </u-tooltip>
              <user-settings-dropdown :user="currentProfileUser" v-if="isFriend">
                <u-button :icon="COMMON_ICONS.moreHorizontal" variant="outline" color="charcoal"/>
              </user-settings-dropdown>
            </u-button-group>
            <user-add-connection-actions v-if="(isRequestSent || isNonFriend) && isAuthenticated && !isPending" :user="currentProfileUser" />
          </div>

        </div>
      </layout-page-head-section>

    </template>

    <div class="flex-1 overflow-y-auto scrollbar-light scroll-smooth -mr-[9px] lg:-mr-[29px] pr-1 lg:pr-6 scrollbar-gutter-stable" ref="listEl">
      <transition-group name="f-slide-fade">
        <core-contextual-loading-box v-if="pending" key="loading" loading-text="Loading Albums" />
        <core-contextual-error-box v-else-if="error" key="error" :action="refresh" />

        <core-empty-state
          v-else-if="!albums.length && isCurrentUser && privacy === ALBUM_PERMISSION_LAYERS.private.value"
          heading="Welcome to Your Profile"
          description="Get started by uploading files in your Gallery. Then, head to Albums and create an album to start organizing. Your albums will display here as a beautiful gallery."
          icon="i-ri-account-circle-line"
        />

        <core-empty-state
          v-else-if="!albums.length"
          heading="Nothing to see here (yet)."
          :description="isFriend || privacy === ALBUM_PERMISSION_LAYERS.family.value ? `Once ${currentProfileUser.name} creates albums shared with you, you'll be able to view them here.` : `Once ${currentProfileUser.name} creates Public albums, you'll be able to view them here.`"
          icon="i-ri-account-circle-line"
        />


        <core-list v-else :items="albums" :scroller-elem="listEl" :disable-approach-bottom="pending || isLoadingMore || !hasMore" @approach-bottom="getMore" :container-class="ALBUM_LIST_CONTAINER_CLASS" :grid-gap-class="ALBUM_LIST_GAP_CLASS">
          <template #default="{ item: album }">
            <album-list-item
              :album="album"
              class="h-full"
              :key="album.id"
              :selectable="false"
              @item-click="onItemClick(album)"
            />
          </template>

<!--          <template #header>
            <div class="" v-if="currentUser.bio">
              <div class="columns-2 xl:columns-3">
                <span class="text-base text-gray-500 whitespace-pre-wrap leading-none" v-html="currentUser.bio.substring(0, 250).concat(currentUser.bio.length > 250 ? '...' : '')" />
              </div>
              <u-divider class="my-3" />
            </div>
          </template>-->
        </core-list>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
  /*todo: break the current user and other user pieces into separate components*/
  import {storeToRefs} from 'pinia';
  import {useScreenSize} from '~/composables/core/screen-size.js';

  const listEl = ref();

  const usersStore = useUsersStore();
  const {currentProfileUser} = storeToRefs(usersStore);

  const sessionStore = useSessionStore();
  const {currentUser, isAuthenticated} = storeToRefs(sessionStore);
  const {shareProfile, editProfile, editThumbnail, openDetailsModal} = useUserActions();
  const {manageUserGroups} = useGroupsActions();

  const route = useRoute();
  const userIdOrSlug = route.params.user_id_or_slug;

  const {sm, xs} = useScreenSize();
  const avatarSize = computed(() => sm.value ? '4xl' : '3xl');
  const bioPreviewLength = computed(() => xs.value ? 250 : 100);

  const friendStatus = computed(() => currentProfileUser.value.friendship.status);
  const isCurrentUser = computed(() => currentProfileUser.value.id === useSessionStore().currentUserId)
  const groupCount = computed(() => currentProfileUser.value.permission_groups_count)
  const isFriend = computed(() => friendStatus.value === FRIEND_STATUS.completed)
  const isPending = computed(() => friendStatus.value === FRIEND_STATUS.pending)
  const isRequestSent = computed(() => friendStatus.value === FRIEND_STATUS.sent)
  const isNonFriend = computed(() => friendStatus.value === FRIEND_STATUS.none)

  const albumsStore = useAlbumsStore();
  const {albums, hasMore} = storeToRefs(albumsStore);

  const {error: userError} = await useAsyncData(
    `user-profile: ${userIdOrSlug}`,
    () => usersStore.getUserProfile({userIdOrSlug})
  );

  if (userError.value) {
    throw createError({
      ...userError.value
    });
  }

  if (isCurrentUser.value) {
    useForeverHead({
      title: `${currentUser.value.name} | Profile`,
      description: currentUser.value.description,
      socialImage: currentUser.value.avatarImage
    });
  } else {
    useForeverHead({
      title: `${currentProfileUser.value.name} | Profile`,
      description: currentProfileUser.value.description,
      socialImage: currentProfileUser.value.avatarImage
    });
  }

  let privacy = ref(isCurrentUser.value ? ALBUM_PERMISSION_LAYERS.private.value : null);
  const {refresh, pending, error} = await useLazyAsyncData(
    'albums',
    () => albumsStore.getAlbums({
      privacy,
      userId: currentProfileUser.value.id
    }),
    {
      watch: [privacy]
    }
  );

  //todo: test pagination
  const getMoreError = ref(null);
  const isLoadingMore = ref(false);
  async function getMore() {
    if (!hasMore.value) {
      return;
    }

    isLoadingMore.value = true;

    try {
      await albumsStore.getAlbums({nextPage: true});
    } catch (e) {
      getMoreError.value = e; //todo: what do we do?
    } finally {
      isLoadingMore.value = false;
    }
  }

  function onItemClick(album) {
    const userId = isCurrentUser.value ? currentUser.value.slug : currentProfileUser.value.slug
    navigateTo(useBuildRoute().toAlbum({album, userId}));
  }

</script>
